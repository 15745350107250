<template>
  <v-list
    :shaped="isShaped"
    dense
  >
    <v-list-item-group
      active-class="active"
      color="primary"
    >
      <ListItem
        v-if="showBannersSection"
        title="Ir para o site"
        route="/"
        target="_blank"
        icon="fas fa-external-link-alt"
      />
      <ListItem
        v-if="showBannersSection"
        title="Banners"
        route="/site/banners"
        icon="fas fa-images"
      />
      <ListItem
        v-if="showContactsSection"
        title="Contato"
        route="/site/contacts"
        icon="fas fa-phone"
      />
      <ListItem
        v-if="showFaqSection"
        title="FAQ"
        route="/site/faq"
        icon="fas fa-question"
      />
      <ListItem
        v-if="showPrintsSection"
        title="Gravações"
        route="/site/prints"
        icon="fas fa-stamp"
      />
      <!-- Esse item agora não aparece para usuários editor, pois showNewsletterSection será false -->
      <ListItem
        v-if="showNewsletterSection"
        title="Novidades - Email"
        route="/site/email-news"
        icon="fas fa-newspaper"
      />
      <ListItem
        v-if="showProductsSection"
        title="Produtos"
        route="/site/products"
        icon="fas fa-star"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
import ListItem from './DefaultListItem.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    isShaped: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters('site', [
      'showBannersSection',
      'showContactsSection',
      'showFaqSection',
      'showPrintsSection',
      'showNewsletterSection',
      'showProductsSection',
    ]),
  },
}
</script>
